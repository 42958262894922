@import "../../../Themes/constants.scss";

.root {
	text-align: left;
	.background-container {
		width: 100%;
		margin: auto;
		max-width: 1440px;
		padding: 2% 0 2% 0;

		.section-container {
			margin: 5% 5% 0 5%;
			gap: 20px;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (max-width: $screen-s) {
				text-align: left;
			}

			.content-container {
				margin: auto;
				align-items: center;
				gap: 160px;

				.content-title {
					max-width: 1162px;
					margin-top: 40px;
					margin-bottom: 20px;

					&:first-child {
						margin-top: 0px;
					}
				}

				.content-text-first {
					margin: auto;
					margin-top: 25px;
					max-width: 750px;
					text-align: center;
				}

				.content-text {
					margin: auto;
					margin-top: 25px;
					max-width: 750px;
				}

				img {
					width: 100%;
					max-width: 1000px;
					margin-top: 60px;
				}
			}
		}
	}
}
