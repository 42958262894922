@import "../../../../Themes/constants.scss";

.root {
	width: 322px;
	height: 322px;
	display: flex;

	@media (max-width: $screen-l) {
		width: 288px;
		height: 288px;
	}

	&:hover {
		.background-container {
			.hover {
				display: flex;
			}
		}
	}

	.background-container {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		padding: 20px;
		flex: 1;
		.background {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			border-radius: 8px 8px 0 0;
		}

		.content {
			z-index: 5;

			.name {
				font-family: var(--font-tertiary);
				font-size: 24px;
				font-weight: 700;
				line-height: 29px;
				letter-spacing: 0em;
				text-align: left;

				color: var(--color-generic-white);
			}

			.role {
				margin-top: 8px;
				font-family: var(--font-tertiary);
				font-size: 18px;
				font-weight: 500;
				line-height: 27px;
				letter-spacing: 0em;
				text-align: left;

				color: var(--color-generic-white);
			}

			.functions {
				font-family: var(--font-tertiary);
				font-size: 18px;
				font-weight: 500;
				line-height: 27px;
				letter-spacing: 0em;
				text-align: left;

				color: var(--color-generic-white);
			}
		}

		.hover {
			border-radius: 8px 8px 0 0;
			display: none;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: #7a43e0;
			z-index: 10;

			align-items: center;

			padding: 16px;

			.list {
				padding-left: 14px;
				margin: 0;
				list-style: none; /* Remove default bullets */
				.list-element {
					font-family: var(--font-tertiary);
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;
					letter-spacing: 0em;
					text-align: left;
					color: white;

					&::before {
						content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
						color: var(--color-generic-white); /* Change the color */
						font-weight: bold; /* If you want it to be bold */
						display: inline-block; /* Needed to add space between the bullet and the text */
						width: 1em; /* Also needed for space (tweak if needed) */
						margin-left: -1em; /* Also needed for space (tweak if needed) */
					}
				}
			}
		}
	}
}
