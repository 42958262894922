@import "../../../Themes/constants.scss";

.root {
	background-color: var(--color-generic-white);
	display: flex;
	justify-content: center;
	.background-container {
		width: 100%;
		max-width: 1440px;
		padding: 90px 0;

		.section-container {
			margin: 24px;
			gap: 20px;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (max-width: $screen-s) {
				margin: 16px;
			}

			.section-title {
				width: 100%;
			}

			.section-subtitle {
				max-width: 740px;
				margin-bottom: 10px;
			}

			.newsletter-frame {
				border: 0;
				text-align: center;
				min-height: 200px;
				width: 100%;
			}
		}
	}
}
