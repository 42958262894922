@import "../../../../Themes/constants.scss";

.root {
	.roadmap-line-desktop {
		display: grid;
		grid-template-columns: 1fr fit-content(0) 1fr;
		align-items: center;

		gap: 100px;
		.dot-container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		@media (max-width: $screen-m) {
			display: none;
		}
	}

	.roadmap-line-mobile {
		display: none;
		width: 320px;
		margin: auto;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media (max-width: $screen-m) {
			display: flex;
		}
	}
}
