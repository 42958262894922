@import "../../../Themes/constants.scss";

.root {
	position: relative;
	top: -100px;
	width: 0;
	height: 0;
	@media (max-width: $screen-m) {
		top: -88px;
	}
}
