@import "../../../Themes/constants.scss";

.root {
	display: flex;
	justify-content: center;
	position: relative;
	overflow: hidden;

	.background-video {
		position: absolute;
		left: -20vw;
		top: 300px;
		width: 140vw;
		z-index: -1;
		
		@media(max-width: 1024px){
			top: 600px;
		}

		@media(max-width: 400px){
			top: 700px;
		}
	}

	.background-container {
		width: 100%;
		max-width: 1440px;
		padding: 2% 0 2% 0;
		z-index: 1;

		.section-container {
			margin: 5% 5% 0 5%;
			gap: 20px;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (max-width: $screen-s) {
				text-align: left;
			}

			.top-corners-container {
				display: flex;
				align-items: flex-start;
				width: 100%;
				justify-content: space-between;
			}

			.bottom-corners-container {
				margin-top: 104px;
				display: flex;
				align-items: flex-start;
				width: 100%;
				justify-content: space-between;
			}

			.section-top {
				display: contents;

				.section-pretitle {
					margin-top: 104px;
				}

				.section-title {
					padding: 20px 0 20px 0;
					width: 100%;
					font-family: var(--font-primary);
					font-size: 70px;
					font-weight: 400;
					line-height: 84px;
					letter-spacing: 0em;
					text-align: center;
					color: var(--color-generic-white);

					@media (max-width: $screen-s) {
						font-size: 28px;
						line-height: 30px;
					}
				}

				.section-subtitle {
					width: 60%;

					@media (max-width: $screen-m) {
						width: 100%;
					}
				}
			}

			.section-video {
				margin-top: 120px;
				display: flex;
				align-items: center;
				justify-content: center;

				.video {
					margin: 20px;
					width: 100%;
					height: auto;
					object-fit: cover;
					border-radius: 16px;
				}
			}

			.section-bottom {
				width: 100%;
				display: flex;
				gap: 24px;

				@media (max-width: $screen-s) {
					flex-direction: column;
					gap: 15px;
				}
			}
		}
	}
}
