@import "./constants.scss";

:root {
	--font-primary: #{$font-primary};
	--font-secondary: #{$font-secondary};
	--font-tertiary: #{$font-tertiary};

	--color-generic-black: #{$color-generic-black};
	--color-generic-white: #{$color-generic-white};
	--color-generic-cyan: #{$color-generic-cyan};

	--color-grey-background: #{$color-grey-background};

	--color-grey-100: #{$color-grey-100};
	--color-grey-300: #{$color-grey-300};

	--color-primary-500: #{$color-primary-500};

	--color-secondary-400: #{$color-secondary-400};
}

@keyframes blink-animation {
	30%,
	35%,
	39% {
		filter: drop-shadow(0px 4px 100px rgba(54, 74, 255, 0.6));
	}
	32%,
	33%,
	38%,
	39% {
		filter: drop-shadow(0px 4px 100px rgba(54, 74, 255, 0.5));
	}
	33%,
	34%,
	38.5% {
		filter: drop-shadow(0px 4px 100px rgba(54, 74, 255, 0.7));
	}
}
