.root {
	width: 36px;
	height: 36px;
	background-color: #ede7ff;
	border-radius: 50px;
	position: relative;
	&::after {
		content: "";
		width: 24px;
		height: 24px;
		background-color: var(--color-secondary-400);
		border-radius: 50px;
		position: absolute;
		top: 6px;
		left: 6px;
	}

	&[data-color="blue"] {
		&::after {
			background-color: var(--color-primary-500);
		}
	}
}
