@import "../../../Themes/constants.scss";

.root {
	display: inline-flex;
	justify-content: center;
	border: 1.5px solid;
	gap: 6px;
	box-sizing: border-box;
	height: fit-content;
	align-items: center;
	background: transparent;
	white-space: nowrap;
	user-select: none;
	cursor: pointer;

	font-family: var(--font-secondary);
	color: var(--color-generic-white);
	border: none;
	font-weight: 700;
	border-radius: 8px;

	svg {
		width: 18px;
		height: 18px;
	}

	&[variant="black"] {
		color: var(--color-generic-white);
		background-color: var(--color-generic-black);
		border-color: var(--color-generic-white);
	}

	&[variant="primary"] {
		background: linear-gradient(270deg, #0305f1 0%, #01f1f2 100%);
		padding: 23px 43px !important;

		&:hover {
			background: linear-gradient(270deg, rgba(3, 5, 241, 0.5) 0%, rgba(1, 241, 242, 0.5) 100%);
		}

		&:focus {
			background: linear-gradient(270deg, rgba(3, 5, 241, 0.7) 0%, rgba(1, 241, 242, 0.7) 100%);
		}

		&:disabled {
			cursor: not-allowed;
			background: linear-gradient(270deg, rgba(3, 5, 241, 0.2) 0%, rgba(1, 241, 242, 0.2) 100%);
		}

		@media (max-width: $screen-s) {
			//Switch to sizng small
			padding: 18px 27px !important;
			font-size: 12px !important;
			line-height: 18px !important;
		}
	}

	&[variant="secondary"] {
		border: 3px solid var(--color-generic-white);

		&:hover {
			background: var(--color-generic-white);
			color: var(--color-primary-500);
		}

		&:focus {
			color: var(--color-primary-500);
			border: 3px solid var(--color-grey-100);
			background-color: var(--color-grey-100);
		}

		&:disabled {
			border: 3px solid var(--color-grey-300);
			color: var(--color-grey-300);
			cursor: not-allowed;

			&:hover {
				border: 3px solid var(--color-grey-300);
				color: var(--color-grey-300);
				background: none;
			}
		}

		@media (max-width: $screen-s) {
			padding: 15px 24px !important;
			font-size: 12px !important;
			line-height: 18px !important;
		}
	}

	&[fullwidthattr="true"] {
		width: 100%;
		flex: 1;
	}

	&[touppercase="false"] {
		text-transform: inherit;
	}

	&[sizing="small"] {
		width: 165px;
		height: 16px;
		padding: 15px 24px;
		font-size: 12px;
		line-height: 18px;
	}

	&[sizing="large"] {
		padding: 20px 40px;
		font-size: 18px;
		line-height: 27px;
	}
}
