@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-Regular.ttf");
	font-weight: normal;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-Bold.ttf");
	font-weight: bold;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-Italic.ttf");
	font-style: italic;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-BoldItalic.ttf");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-Black.ttf");
	font-weight: 900;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-BlackItalic.ttf");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-ExtraBold.ttf");
	font-weight: 800;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-ExtraBoldItalic.ttf");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-ExtraLight.ttf");
	font-weight: 200;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-ExtraLightItalic.ttf");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-Light.ttf");
	font-weight: 300;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-LightItalic.ttf");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-SemiBold.ttf");
	font-weight: 600;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-SemiBoldItalic.ttf");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: Franie;
	src: url("./fonts/franie/Franie-ExtraLightItalic.ttf");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: BankGothic;
	src: url("./fonts/bank-gothic/BankGothic Md BT.ttf");
}
