@import "../../Themes/variables.scss";

body {
    min-height: 100vh;
    margin: 0;

    a {
        text-decoration: none;
        color: inherit;
    }

    background-color: var(--color-grey-background);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
    visibility: hidden;
}
