@import "../../Themes/variables.scss";

.content {
	margin: auto;
}

.header {
	position: sticky;
	top: 0;
	background-color: var(--color-grey-background);
	z-index: 100;

	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: $screen-l - 240px;
		margin: auto;
		color: white;
		height: 48px;
		padding: 28px 0;

		@media (max-width: ($screen-l - 150px)) {
			height: 40px;
			padding: 28px 24px;
			max-width: unset;
		}

		.logo-img {
			width: 72px;
		}

		.links-container {
			display: flex;
			gap: 32px;

			.link {
				color: #ede7ff;
				font-family: var(--font-secondary);
				font-size: 12px;
				font-weight: 600;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;

				&:hover {
					color: var(--color-generic-white);
					flex-shrink: 0;
				}
			}
		}

		.burger-container {
			display: none;
			@media (max-width: ($screen-l - 150px)) {
				display: block;
			}
		}

		@media (max-width: ($screen-l - 150px)) {
			.connect-button,
			.links-container {
				display: none;
			}
		}
	}
}

.footer {
	background: var(--color-grey-background);
	.footer-container {
		max-width: $screen-l - 240px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		padding: 64px 0 24px 0;
		gap: 80px;

		@media (max-width: ($screen-l - 150px)) {
			max-width: 100vw;
			overflow-x: auto;
			overflow-y: hidden;

			display: grid;
			grid-template-columns: repeat(2, 1fr);

			padding: 90px 24px 32px 24px;
		}

		@media (max-width: $screen-s) {
			grid-template-columns: repeat(1, 1fr);
			padding: 60px 24px 32px 24px;
		}

		.separator {
			flex: 1;
			max-width: 1px;
			background-color: #2c2c4a;

			@media (max-width: ($screen-l - 150px)) {
				display: none;
			}
		}

		.part {
			display: flex;
			flex-direction: column;
			flex: 1;
			align-items: flex-start;

			.part-logo {
				align-self: flex-start;
			}

			.part-text {
				font-family: Franie;
				font-size: 10px;
				font-weight: 500;
				line-height: 15px;
				letter-spacing: 0em;
				text-align: left;
				color: #ededed;
				margin-top: 24px;
			}

			.part-title {
				font-family: Franie;
				font-size: 24px;
				font-weight: 700;
				line-height: 29px;
				letter-spacing: 0em;
				text-align: center;
				color: var(--color-generic-white);
				white-space: nowrap;
			}

			.part-links {
				display: flex;
				flex-direction: column;
				margin-top: 24px;
				gap: 16px;
				.link {
					//styleName: H4 Link footer;
					font-family: Franie;
					font-size: 10px;
					font-weight: 600;
					line-height: 15px;
					letter-spacing: 0em;
					text-align: left;
					color: var(--color-grey-100);
					text-decoration: underline;
					cursor: pointer;

					&:hover {
						color: var(--color-generic-white);
						flex-shrink: 0;
					}
				}
			}
		}
	}

	.rights {
		font-family: Franie;
		font-size: 10px;
		font-weight: 500;
		line-height: 15px;
		letter-spacing: 0em;
		text-align: center;
		opacity: 0.3;
		color: var(--color-generic-white);

		padding: 15px 0;
	}
}
