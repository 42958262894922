@import "../../../../Themes/constants.scss";

.root {
	display: flex;
	width: 272px;
	height: 500px;
	padding: 70px 64px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	flex-shrink: 0;
	border-radius: 8px;
	background: linear-gradient(135deg, rgba(245, 245, 250, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
	border: 2px solid #9898a0;

	&:hover {
		background: #0305f1;
	}

	@media (max-width: $screen-m) {
		width: 256px;
		// height: auto;
		padding: 32px 16px;
	}

	.background-container {
		.picto {
			width: 50px;
			height: 50px;
			border-radius: 8px 8px 0 0;
			padding-bottom: 24px;
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				color: var(--color-generic-white);
				text-align: center;

				/* desktop/H3 Title */
				font-family: var(--font-secondary);
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 120%; /* 28.8px */
			}

			.subtitle {
				color: #c8c8dd;

				/* Paragraph Regular */
				font-family: var(--font-secondary);
				font-size: 15px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%; /* 22.5px */
			}
		}
	}
}
