.root {
	width: 100%;
	.container {
		padding: 40px;
		border-radius: 8px;
		border: 2px solid #9898a0;
		background: linear-gradient(98.53deg, rgba(255, 255, 255, 0.2) 0%, rgba(245, 245, 250, 0.05) 100%),
			linear-gradient(98.53deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 47.4%, rgba(255, 255, 255, 0.5) 100%);

		&:hover {
			background: #0305f1;
			border: 2px solid #0305f1;
			box-shadow: 0px 4px 150px 0px #0305f180;
		}
		.text {
			margin-top: 16px;
		}
	}
}
