@import "../../../Themes/constants.scss";

.root {
	text-align: left;

	.background-lane {
		background-repeat: repeat;
		height: 60px;
	}
	.background-container {
		max-width: 1440px;
		margin: auto;
		position: relative;

		.section-container {
			margin: 5% 5% 0 5%;
			gap: 20px;

			display: flex;
			flex-direction: column;
			align-items: center;

			@media (max-width: $screen-s) {
				text-align: left;
			}

			.section-top {
				.section-title {
					text-transform: uppercase;
				}
			}

			.content-container {
				display: flex;

				align-items: left;
				gap: 160px;
				margin-top: 24px;

				@media (max-width: $screen-l) {
					gap: 160px;
				}

				@media (max-width: $screen-m) {
					gap: 10px;
				}

				@media (max-width: $screen-s) {
					gap: 10px;
				}

				@media (max-width: $screen-m) {
					flex-direction: column;
					align-items: center;
				}
				.content-left {
					display: flex;
					flex-direction: column;
					justify-content: center;
					text-align: left;
					margin-top: 20px;

					.content-title {
						text-transform: uppercase;
						margin-top: 30px;
						margin-bottom: 10px;

						&:first-child {
							margin-top: 0px;
						}
					}

					.content-text {
						color: #c8c8dd;
						font-family: var(--font-secondary);
						font-size: 15px;
						font-style: normal;
						font-weight: 500;
						line-height: 150%;
						margin-top: 25px;
						text-align: left !important;

						.sale-date {
							color: #01f1f2;
						}
					}
				}

				.content-right {
					> img {
						width: 305px;
						height: auto;

						@media (max-width: $screen-s) {
							width: 272px;
						}
					}
				}
			}

			.section-bottom {
				margin-top: 40px;
				display: flex;
				justify-content: center;
			}
		}

		.top-lines {
			position: absolute;
			top: 0;
			right: 0;

			@media (max-width: ($screen-l + 100px)) {
				right: 100px;
			}

			@media (max-width: $screen-s) {
				display: none;
			}
		}
	}
}
