@import "../../../Themes/constants.scss";

.root {
	display: flex;
	justify-content: center;
	position: relative;
	overflow: hidden;

	.background-video {
		position: absolute;
		left: -40vw;
		top: 0;
		width: 100vw;
		z-index: -1;
		transform: rotate(-30deg);

		@media (max-width: 1024px) {
			width: 120vw;
		}
	}

	.background-video2 {
		position: absolute;
		right: -40vw;
		bottom: 0;
		width: 100vw;
		z-index: -1;
		transform: rotate(30deg);

		@media (max-width: 1024px) {
			width: 120vw;
		}
	}

	.background-container {
		width: 100%;
		max-width: 1440px;
		padding: 2% 0 2% 0;

		.section-header {
			text-align: center;

			.title {
				max-width: 1000px;
				margin: auto;
			}

			.text {
				max-width: 740px;
				margin: auto;
				margin-top: 40px;
			}
		}

		.roadmap-container {
			margin-top: 128px;
			padding: 0 60px;

			@media (max-width: $screen-m) {
				padding: 0;
			}

			.last-line {
				.dot-container {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}

			.last-block {
				margin: auto;
				margin-top: 48px;
				width: 519px;
				background: var(--color-primary-500);
				border-radius: 8px;
				padding: 40px;

				box-shadow: 0px 4px 150px 0px #0305f180;

				@media (max-width: $screen-m) {
					/* 320px - padding */
					width: calc(320px - 80px);
				}

				.text {
					margin-top: 16px;
				}
			}

			.separator {
				margin: auto;
				margin-top: 160px;
				width: 967px;

				@media (max-width: $screen-l) {
					width: 90%;
				}

				@media (max-width: $screen-m) {
					width: 100%;
				}
			}
		}
	}
}
