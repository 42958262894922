@import "../../../Themes/constants.scss";

.container {
	&.white {
		color: var(--color-generic-white);
	}

	&.black {
		color: var(--color-generic-black);
	}

	&.H1-title {
		font-family: var(--font-primary);
		color: #fff;
		text-align: center;
		font-size: 70px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;

		@media (max-width: $screen-s) {
			font-family: var(--font-secondary);
			font-size: 32px;
			font-weight: 700;
		}
	}

	&.H2-title {
		font-family: var(--font-primary);
		color: #fff;
		font-size: 50px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 60px */

		@media (max-width: $screen-s) {
			font-family: var(--font-secondary);
			font-size: 24px;
			font-weight: 700;
		}
	}

	&.H3-title {
		font-family: var(--font-secondary);
		color: #fff;
		text-align: center;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;

		@media (max-width: $screen-s) {
			font-family: var(--font-secondary);
			font-size: 20px;
		}
	}

	&.text-card-18 {
		color: #fff;
		text-align: center;
		font-family: var(--font-tertiary);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	&.text-card-14 {
		color: #fff;
		text-align: center;
		font-family: var(--font-tertiary);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	&.paragraph-18-regular {
		color: #fff;
		text-align: center;
		font-family: var(--font-secondary);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	&.paragraph-15-regular {
		color: #c8c8dd;
		text-align: center;
		font-family: var(--font-secondary);
		font-size: 15px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;

		@media (max-width: $screen-s) {
			color: #fff;
			font-family: var(--font-secondary);
			font-size: 15px;
		}
	}

	&.paragraph-10-regular {
		color: #ededed;
		font-family: var(--font-secondary);
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	&.nav-menu-12 {
		color: #ede7ff;
		font-family: var(--font-secondary);
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}
