@import "../../../Themes/constants.scss";

.root {
	display: flex;
	justify-content: center;
	.background-container {
		width: 100%;
		max-width: 1440px;
		padding: 2% 0 160px 0;

		.section-container {
			padding: 0 16px;
			.text-container {
				.title {
					text-align: center;
				}

				.text {
					text-align: center;

					max-width: 750px;
					margin: auto;
					margin-top: 32px;
				}
			}

			.members-container {
				flex: 1;
				margin: auto;
				margin-top: 120px;
				max-width: 1200px;
				display: flex;
				flex-wrap: wrap;
				row-gap: 128px;
				justify-content: space-between;
				@media (max-width: $screen-l) {
					justify-content: center;
					gap: 128px;
				}

				@media (max-width: $screen-m) {
					gap: 60px;
				}

				@media (max-width: $screen-s) {
					gap: 40px;
				}
			}

			.partners-container {
				.partners-title {
					margin-top: 128px;
					text-align: center;
				}

				.partners {
					flex: 1;
					margin: auto;
					margin-top: 70px;
					max-width: 1200px;
					display: flex;
					flex-wrap: wrap;
					row-gap: 128px;
					justify-content: space-between;
					@media (max-width: $screen-l) {
						justify-content: center;
						gap: 128px;
					}

					@media (max-width: $screen-m) {
						gap: 60px;
					}

					@media (max-width: $screen-s) {
						gap: 40px;
					}
				}
			}
		}

		.logos-container {
			max-width: 1200px;
			margin: auto;
			margin-top: 128px;

			display: flex;
			justify-content: space-between;
			align-items: center;

			.logo-div {
				display: flex;
				justify-content: center;
			}

			@media (max-width: $screen-l) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				row-gap: 30px;
			}

			@media (max-width: $screen-s) {
				grid-template-columns: repeat(1, 1fr);
			}
		}

		.lets-connect {
			margin-top: 128px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 32px;
			padding: 0 16px;
			.title {
				text-align: center;
			}

			.text {
				text-align: center;
				max-width: 744px;
			}
		}
	}
}
