@import "../../../Themes/constants.scss";

.root {
	padding-top: 160px;
	display: flex;
	justify-content: center;
	position: relative;
	overflow: hidden;

	.background-video {
		position: absolute;
		right: -40vw;
		top: 0;
		width: 100vw;
		z-index: -1;
		transform: rotate(-30deg);

		@media(max-width: 1024px){
			width: 120vw;
		}
	}

	.background-container {
		width: 100%;
		max-width: 1440px;
		padding: 2% 0 160px 0;
		position: relative;

		.section-container {
			padding: 0 16px;

			.text-container {
				.title {
					text-align: center;
				}

				.text {
					text-align: center;

					max-width: 750px;
					margin: auto;
					margin-top: 32px;
				}
			}

			.features-container {
				margin: auto;
				margin-top: 120px;
				max-width: 1200px;
				display: flex;
				flex-wrap: wrap;
				row-gap: 128px;
				justify-content: space-between;
				justify-content: center;
				gap: 128px;

				@media (max-width: $screen-m) {
					gap: 60px;
				}
			}
		}

		.separator {
			position: absolute;
			top: 200px;
			right: 0;
			height: 1300px;

			@media (max-width: $screen-m) {
				display: none;
			}
		}

		.xxx {
			position: absolute;
			top: -150px;

			@media (max-width: ($screen-l + 100px)) {
				left: 85px;
				top: -200px;
			}

			@media (max-width: $screen-s) {
				display: none;
			}
		}
	}
}
