@import "../../../../../Themes/constants.scss";

.root {
	width: 6px;
	height: 150px;
	background-color: #ede7ff;

	@media (max-width: $screen-m) {
		height: 32px;
	}
}
