@import "../../../../Themes/constants.scss";

.root {
	display: flex;
	flex: 1;
	width: 100%;
	.container {
		width: fit-content;
		padding: 20px 40px;
		border-radius: 8px;
		border: 2px solid transparent;
		background: #0305f1;

		@media (max-width: $screen-m) {
			width: 100%;
		}
	}
}
