@import "../../../Themes/variables.scss";

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-in-reverse {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.root {
	display: flex;
	pointer-events: none;
	opacity: 0;
	z-index: 100;
	&[data-open="open"] {
		pointer-events: all;
		animation: fade-in 300ms ease-in-out 0s 1 normal forwards;
	}

	&[data-open="closed"] {
		animation: fade-in-reverse 300ms ease-in-out 0s 1 reverse forwards;
	}

	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
    //backdrop-filter: blur(40px);

	.background {
		position: absolute;
		background-color: var(--color-grey-background);
		opacity: 0.3;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.content {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: rgba($color: $color-grey-background, $alpha: 0.9);
		min-width: 252px;
		max-height: calc(100vh - 104px);
		top: 104px;

		@media (max-width: ($screen-l - 150px)) {
			max-height: calc(100vh - 96px);
			top: 96px;
		}

		right: 0;
		border-bottom-left-radius: 16px;
		overflow-y: auto;
		filter: drop-shadow(0px 4px 100px rgba(54, 74, 255, 0.6));

		animation: blink-animation 6s infinite linear alternate;
		.links-container {
			display: flex;
			flex-direction: column;
			margin: auto;
			gap: 32px;
			color: white;
			padding: 40px 0;
			.link {
				font-family: Franie;
				font-size: 12px;
				font-weight: 600;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
			}
		}

		.connect-button {
			margin: auto;
			padding: 0 0 40px 0;
			max-width: 165px;
		}

		@media (max-width: $screen-xs) {
			width: 100vw;
			height: 100vh;
			overflow-x: auto;
		}
	}
}
